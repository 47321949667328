import { ReactChildren, useEffect } from 'react';
import { useApollo } from '@utils/wire';
import { ApolloProvider } from '@apollo/client/react';
import { GET_USER_INFO_BY_FIELD } from '@schemas/queries/users';

type Props = {
  children: ReactChildren,
  pageProps: Object,
  setIsLogin: Function,
  setMyId: Function,
};

export default function CustomApolloProvider({
  children,
  pageProps,
  setIsLogin,
  setMyId,
}: Props) {
  const apolloClient = useApollo(pageProps);

  useEffect(async () => {
    try {
      const { data } = await apolloClient.query({
        query: GET_USER_INFO_BY_FIELD(),
      });
      if (data) {
        setIsLogin(true);
        setMyId(data.user.id);
      }
    } catch (e) {
      setIsLogin(false);
      setMyId('');
    }
  }, []);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
